export enum LAMBDA_ERROR_CODE {
  PERSON_ALREADY_EXISTS = 2305,
  AT_LEAST_ONE_LEGAL_REPRESENTATIVE = 2315,
}

enum ERRORS {
  NOT_FOUND = 'Não encontrado',
  INTERNAL_ERROR = 'Ops, ocorreu um erro inesperado',
  CUSTOMER_LOADING_ERROR = 'Não foi possível carregar as informações desse cliente',
  INVOICING_PLANS_LOADING_ERROR = 'Não foi possível carregar os planos desse cliente',
  MODULE_LOADING_ERROR = 'Não foi possível carregar as informações desse módulo',
  METRICS_LOADING_ERROR = 'Não foi possível carregar visão geral dos status.',
  INVOICE_HISTORY_LOADING_ERROR = 'Não foi possível carregar histórico de contas.',
  DOES_NOT_EXIST = 'Ops! Essa página não existe ou foi removida.',
  COPY_INVOICE_LINK_ERROR = 'Não foi possível copiar o link da conta.',
  GET_PERSON_ERROR = 'Não foi possível carregar as informações dessa pessoa',
  GET_PERSONS_ERROR = 'Não foi possível carregar as informações das pessoas associadas a este local',
  GET_COMPANY_ERROR = 'Não foi possível carregar as informações da empresa',
  GET_PLACE_ERROR = 'Não foi possível carregar as informações do lugar',
  GET_COMPANY_PLACES_ERROR = 'Não foi possível carregar as informações dos lugares relacionados a esta empresa',
  UPDATE_PERSON_FAIL = 'Não foi possível atualizar informações da pessoa',
  CREATE_PERSON_FAIL = 'Não foi possível criar a pessoa',
  UPDATE_PLACE_FAIL = 'Não foi possível atualizar informações do lugar',
  UPSERT_PLACE_RELATION_FAIL = 'Não foi possível atualizar relações do lugar',
  UPSERT_COMPANY_RELATION_FAIL = 'Não foi possível atualizar relações do lugar pertencente a uma empresa',
  DELETE_PLACE_RELATION_FAIL = 'Não foi possível deletar relação do lugar',
  UPDATE_COMPANY_FAIL = 'Não foi possível atualizar informações da empresa',

  MISSING_CUSTOMER = 'Cliente não encontrado.',
  MISSING_CUSTOMER_ID = 'O id do cliente não foi informado',
  MISSING_REFERENCE_MONTH = 'A data do mês de referência do invoice do plano não foi informada',
  MISSING_NEGOTIATION_ID = 'O id da negociação não foi informado',
  MISSING_INVOICING_PLAN_ID = 'O id do plano não foi informado',
  MISSING_INVOICE_ID = 'O id da conta não foi informado',
  MISSING_INVOICES_FIRST_REFERENCE_DATE = 'A data do primeiro invoice do plano não foi informada',
  MISSING_REFERENCE_MONTH_INVOICE = 'O invoice do mês de referência não foi informado',
  MISSING_INVOICING_PLAN_METRICS = 'As métricas do plano não foram informadas',
  MISSING_NEGOTIATION_TOTAL = 'O número total de negociações não foi informado',
  MISSING_INTERVAL = 'O intervalo de datas ou data base não foi informado',

  MISSING_FINE_AND_INTEREST_VALUES = 'Valores de multas e juros não foram informados',

  DATE_BEFORE_FIRST_REFERENCE_DATE = 'Data inicial não pode ser anterior que a primeira data de referência',
  MISSING_SEARCH = 'O parâmetro de busca não foi informado',
  SEARCH_ERROR = `Não foi possível processar a sua busca no momento.\n Por favor, retorne à página inicial e tente novamente.`,

  PAGE_LOAD_ERROR = 'Não foi possível carregar as informações desta página.',
  ACCESS_TOKEN_REQUIRED = 'Senha do documento é obrigatória',
  INVALID_ACCESS_TOKEN_TYPE = 'Senha do documento deve conter somente letras e números',
  INVALID_ACCESS_TOKEN_SIZE = 'Senha do documento deve entre 10 e 20 caracteres',

  COULD_NOT_SAVE_CHANGES = 'Não foi possível salvar as alterações. Por favor, tente novamente.',

  GENERIC_LAMBDA_ERROR = 'Ocorreu um erro interno inesperado.',
}

/**
 * Some use cases for STATUS_TEXTS include disambiguation
 * of errors and exceptions with the same status code, or
 * to be sent via some analytics tool.
 */

export enum STATUS_TEXTS {
  PAGE_NOT_FOUND = 'Página não encontrada',
  UNCAUGHT_EXCEPTION = 'Exceção não capturada',
}

export default ERRORS
